<template>
    <div class="helpcenter">
        <div class="banner"> 
            <div class="banner-container">
                <div>
                    <h2>扬歌SDK</h2>
                    <h4></h4>
                    <p>是依托扬歌平台提供一整套变现方案，让流量更具价值的聚合SDK。</p>
                </div>
                <img src="../assets/img/app_development.png" alt="#">
            </div>
        </div>
        <div class="initiative-ad">
            <div class="initiative-ad-container section">
                <h2>创意广告</h2>
                <p>扬歌广告模块聚合了国内主流广告平台（优量汇、穿山甲、百度、快手、华为、小米、ViVo）</p>
                <p>依托于扬歌平台智能化广告策略与算法，通过任务、活动等最大程度的创造广告展示场景，增强用户互动，最大化提升流量价值。</p>
                <ul>
                    <li>
                        <img src="../assets/img/ad_main1.png" alt="">
                        <div>
                            <h3>聚合性</h3>
                            <p><span></span><i>集成了国内主流广告平台：优量汇、穿山甲、百度、快手、华为、小米、ViVo</i></p>
                            <p><span></span><i>支持多种广告形式：开屏、横幅、插屏、模板、模板视频信息流、信息流、全屏视频、激励视频、视频内容</i></p>
                        </div>
                    </li>
                    <li>
                        <img src="../assets/img/ad_main2.png" alt="">
                        <div>
                            <h3>专业性</h3>
                            <p><span></span><i>处理每日上亿请求</i></p>
                            <p><span></span><i>各类型广告CPM提升10% -50%</i></p>
                            <p><span></span><i>一套代码快速接入国内主流广告平台；</i></p>
                        </div>
                    </li>
                    <li>
                        <img src="../assets/img/ad_main3.png" alt="">
                        <div>
                            <h3>智能化</h3>
                            <p><span></span><i>广告策略智能控制（广告主切换、UPV控制、智能填充、精投策略等）</i></p>
                            <p><span></span><i>自动优化算法，提升每一次请求的价值</i></p>
                            <p><span></span><i>支持自有广告、私有化售卖</i></p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="ec-ecosystem">
            <div class="ec-ecosystem-container section">
                <h2>电商生态圈</h2>
                <p>扬歌电商模块聚合了国内主流电商平台（淘宝、京东、拼多多，后续会陆续接入更多平台）</p>
                <p>专注于给渠道提供网络赚钱、流量变现、专业电商CPS营销，致力于打造精准、高效、高转化的电商营销生 态圈，让您的流量更具价值</p>
                <ul>
                    <li>
                        <h5>
                            <img src="../assets/img/ec1.png" alt="">
                            <span>高效接入</span>
                        </h5>
                        <div>
                            <p><span></span><i>一分钟快速接入国内各大电商平台，实现自己的电商CPS分销系统</i></p>
                            <p><span></span><i>避免繁杂的接入流程</i></p>
                            <p><span></span><i>节省大量的研发周期</i></p>
                            <p><span></span><i>降低大量的维护成本</i></p>
                        </div>
                    </li>
                    <li>
                        <h5>
                            <img src="../assets/img/ec2.png" alt="">
                            <span>领劵、分佣</span>
                        </h5>
                        <div>
                            <p><span></span><i>快速领取国内各大电商平台优选商品优惠券</i></p>
                            <p><span></span><i>智能搜索各大电商平台领券商品</i></p>
                            <p><span></span><i>自定义用户返佣比例</i></p>
                            <p><span></span><i>用户下单领取佣金</i></p>
                        </div>
                    </li>
                    <li>
                        <h5>
                            <img src="../assets/img/ec3.png" alt="">
                            <span>电商分销</span>
                        </h5>
                        <div>
                            <p><span></span><i>充分利用私域流量，订单关联用户</i></p>
                            <p><span></span><i>平台智能选品，订单实时同步</i></p>
                            <p><span></span><i>透传分享商品信息，渠道自由控制分享业务</i></p>
                            <p><span></span><i>提供高佣转链，关联分享者、购买者</i></p>
                        </div>
                    </li>
                    <li>
                        <h5>
                            <img src="../assets/img/ec4.png" alt="">
                            <span>需求定制</span>
                        </h5>
                        <div>
                            <p><span></span><i>定制需求评估排期</i></p>
                            <p><span></span><i>支持电商页面多模块定制</i></p>
                            <p><span></span><i>定制模块信息透传</i></p>
                            <p><span></span><i>打造个性化电商CPS系统</i></p>
                        </div>
                    </li>
                </ul>
                <ul>
                    <li>
                        <div>
                            <h4>闲玩模块</h4>
                            <h5>FREE PLAY MODULE</h5>
                            <img src="../assets/img/xianwan1.png" alt="">
                        </div>
                        <div>
                            <p>扬歌闲玩模块可以快速实现深度体验式（CPL）游戏试玩平台，为开发者的用户流量发挥最大的价值</p>
                            <p><span></span><i>一行码高效接入</i></p>
                            <p><span></span><i>用户订单实时跟踪、同步</i></p>
                            <p><span></span><i>支持自定义用户返佣比例、返佣类型     </i></p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h4>任务、活动系统</h4>
                            <h5>TASK ACTIVITY SYSTEM</h5>
                            <img src="../assets/img/xianwan2.png" alt="">
                        </div>
                        <div>
                            <p>扬歌任务、活动系统通过专业的运营手段，提升用户活跃，增加广告等变现的场景，增强用户互动，从而提升变现能力</p>
                            <p><span></span><i>一行码高效接入</i></p>
                            <p><span></span><i>用户打通，信息同步</i></p>
                            <p><span></span><i>提升广告展示场景，提升变现</i></p>
                            <p><span></span><i>专业的运营，提升用户活跃与互动</i></p>
                            <p><span></span><i>定制化服务，打造个性化任务、活动</i></p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h4>促活裂变工具</h4>
                            <h5>PROMOTING FISSION TOOLS</h5>
                            <img src="../assets/img/xianwan3.png" alt="">
                        </div>
                        <div>
                            <p>扬歌SDK提供了一整套的提升用户活跃、增强用户裂变的工具，渠道方通过授权扬歌平台，与扬歌平台用户系统打通，即可使用促活裂变工具</p>
                            <p><span></span><i>一行码高效接入</i></p>
                            <p><span></span><i>可视化工具提升用户活跃度</i></p>
                            <p><span></span><i>多种运营方式提升用户裂变率</i></p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');
    .helpcenter{
        .banner{
            background:url('../assets/img/type-banner-bg.png');
            background-size: contain;
            .banner-container{
                width: 1200px;
                margin: 0 auto;
                position: relative;
                display: flex;
                align-items: center;
                height: 430px;
                img{
                    position: absolute;
                    top: 10px;
                    right: 15px;
                    width: 481px;
                }
                >div{
                    h2{
                        font-size: 40px;
                        font-weight: 600;
                    }
                    h4{
                        background: @themeColor;
                        width: 32px;
                        height: 4px;
                        margin: 12px 0 24px;
                    }
                    p{
                        font-size: 18px;
                        width: 460px;
                    }
                }
            }
        }
        .initiative-ad{
            background: #fefefe;
            .initiative-ad-container{
                width: 1200px;
                margin: 0 auto;
                ul{
                    justify-content: space-between;
                    li{
                        width: 32%;
                        border: 1px solid #dedede;
                        border-radius: 8px;
                        overflow: hidden;
                        >div{
                            padding: 20px;
                            h3{
                                font-size: 20px;
                                font-weight: 600;
                                margin-bottom: 20px;
                            }
                            p{
                                font-size: 14px;
                                margin-bottom: 10px;
                                i{
                                    width: 320px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .ec-ecosystem{
            background: #f6f7f8;
            padding-bottom: 120px;
            .ec-ecosystem-container{
                width: 1200px;
                margin: 0 auto;
                ul:nth-of-type(1){
                    flex-wrap: wrap;
                    justify-content: space-between;
                    li{
                        box-shadow: 3px 3px 6px #dedede;
                        margin-bottom: 20px;
                        background: #fefefe;
                        width: 590px;
                        padding: 50px;
                        border-radius: 6px;
                        h5{
                            img{
                                width: 60px;
                                margin-right: 16px;
                            }
                            display: flex;
                            align-items: center;
                            font-size: 22px;
                            font-weight: 600;
                            margin-bottom: 20px;
                        }
                        p{
                            margin: 8px 0;
                        }
                    }
                }
                ul:nth-of-type(2){
                    justify-content: space-between;
                    li{
                        background: #fefefe;
                        border-radius: 8px;
                        width: 32%;
                        box-shadow: 5px 5px 8px #dedede;
                        div:nth-child(1){
                            position: relative;
                            color: white;
                            h4{
                                font-size: 32px;
                                position: absolute;
                                top: 50px;
                                left: 40px;
                            }
                            h5{
                                font-size: 14px;
                                position: absolute;
                                top: 90px;
                                left: 40px;
                            }
                        }
                        div:nth-child(2){
                            padding: 20px;
                            p{
                                margin-bottom: 12px;
                                &:nth-child(1){
                                    margin-bottom: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .section{
            padding: 80px 0;
            >h2{
                font-weight: 600;
                font-size: 36px;
                text-align: center;
                margin: 26px 0;
            }
            >p{
                font-size: 18px;
                text-align: center;
            }
            ul{
                display: flex;
                margin:60px 0 50px;
                li>div>p{
                    display: flex;
                    align-items: center;
                    span{
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        border-radius: 2px;
                        background: @themeColor;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
   
</style>